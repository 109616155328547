import AccessToken from 'domain/entity/session/models/AccessToken'
import RefreshToken from 'domain/entity/session/models/RefreshToken'
import Verification from 'domain/entity/verification/models/Verification'
import AuthRepository from 'domain/repository/auth/AuthRepository'
import SessionRepository from 'domain/repository/session/SessionRepository'
import VerificationRepository from 'domain/repository/verification/VerificationRepository'
import { TokenPair } from 'grpc/gen/entrum/lite/v1/lite'

export default class LoginUseCase {
	private authRepository: AuthRepository
	private sessionRepository: SessionRepository
	private verificationRepository: VerificationRepository

	public constructor(
		authRepository: AuthRepository,
		sessionRepository: SessionRepository,
		verificationRepository: VerificationRepository,
	) {
		this.authRepository = authRepository
		this.sessionRepository = sessionRepository
		this.verificationRepository = verificationRepository
	}

	public getActiveVerificationMaybe(): Verification | null {
		const verification = this.verificationRepository.getVerification()
		if (verification == null) {
			this.verificationRepository.destroyVerification()
			return null
		}
		if (!verification.isTransactionActive() && !verification.isCertificationActive()) {
			this.verificationRepository.destroyVerification()
			return null
		}
		return verification
	}

	public async verificationEmail(email: string): Promise<Verification | null> {
		const verification = await this.authRepository.verificationEmail(email)
		if (verification != null) {
			this.verificationRepository.saveVerification(verification)
		}
		return verification
	}

	public async verificationEmailCode(verification: Verification, code: string): Promise<Verification | null> {
		const verificationEmailCode = await this.authRepository.verificationEmailCode(verification, code)
		if (verificationEmailCode != null) {
			this.verificationRepository.saveVerification(verificationEmailCode)
		}
		return verificationEmailCode
	}

	public async signIn(certificateId: string): Promise<TokenPair | null> {
		const tokenPair = await this.authRepository.signIn(certificateId)
		if (tokenPair != null) {
			this.sessionRepository.saveAccessToken(new AccessToken(tokenPair.idToken))
			this.sessionRepository.saveRefreshToken(new RefreshToken(tokenPair.refreshToken))
			this.verificationRepository.destroyVerification()
		}
		return tokenPair
	}

	public async refreshToken(refreshToken: string): Promise<TokenPair | null> {
		const tokenPair = await this.authRepository.refreshToken(refreshToken)
		if (tokenPair != null) {
			await this.sessionRepository.saveAccessToken(new AccessToken(tokenPair.idToken))
			await this.sessionRepository.saveRefreshToken(new RefreshToken(tokenPair.refreshToken))
		}
		return tokenPair
	}
}
