import SessionUseCase from 'domain/usecase/session/SessionService'
import SessionRepository from 'domain/repository/session/SessionRepository'
import { RpcOptions, UnaryCall, NextUnaryFn, MethodInfo } from '@protobuf-ts/runtime-rpc'

// implementation from https://github.com/timostamm/protobuf-ts/issues/31
export default function AuthorizationInterceptor() {
	return {
		interceptUnary(next: NextUnaryFn, method: MethodInfo, input: object, options: RpcOptions): UnaryCall {
			const useCase = new SessionUseCase(new SessionRepository())
			const callPromise = new Promise<UnaryCall>(resolve => {
				const accessToken = useCase.getAccessToken()
				if (!options.meta) {
					options.meta = {}
				}
				if (accessToken) {
					options.meta['Authorization'] = accessToken.getRaw()
				}
				resolve(next(method, input, options))
			})

			return new UnaryCall(
				method,
				options.meta ?? {},
				input,
				callPromise.then(c => c.headers),
				callPromise.then(c => c.response),
				callPromise.then(c => c.status),
				callPromise.then(c => c.trailers),
			)
		},
	}
}
