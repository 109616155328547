import { GrpcWebFetchTransport } from '@protobuf-ts/grpcweb-transport'
import { RpcOptions } from '@protobuf-ts/runtime-rpc'
import {
	GetProfileRequest,
	GetProfileResponse,
	Profile,
	UpdateProfileRequest,
} from 'grpc/gen/foodhunt/profile/v1/profile'
import { ProfileAPIClient } from 'grpc/gen/foodhunt/profile/v1/profile.client'
import { getResponseFromCall } from 'grpc/utils/response'
import { ServiceBaseUrl } from '../../../const'
import AuthorizationInterceptor from '../../../grpc/interceptors/authInterceptor'

export default class ProfileRepository {
	private client: ProfileAPIClient
	private options: RpcOptions

	constructor() {
		this.client = new ProfileAPIClient(
			new GrpcWebFetchTransport({
				baseUrl: ServiceBaseUrl,
			}),
		)
		this.options = {
			interceptors: [AuthorizationInterceptor()],
		}
	}

	public async getProfile(): Promise<Profile | null> {
		const request = GetProfileRequest.fromJson({})
		const response = (await getResponseFromCall(this.client.getProfile(request, this.options))) as GetProfileResponse
		return response.profile ?? null
	}

	public async updateProfile(profile: Profile) {
		const request = UpdateProfileRequest.fromJson({})
		request.profile = profile
		await getResponseFromCall(this.client.updateProfile(request, this.options))
	}
}
