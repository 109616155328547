import React, { PropsWithChildren } from 'react'
import { Profile } from 'grpc/gen/foodhunt/profile/v1/profile'
import VerifyAndRefreshTokenInterceptor from './grpc/interceptors/refreshTokenInterceptor'
import ProfileUseCase from './domain/usecase/profile/ProfileService'
import ProfileRepository from './domain/repository/profile/ProfileRepository'
import SessionUseCase from './domain/usecase/session/SessionService'
import SessionRepository from './domain/repository/session/SessionRepository'
import { LoadingSpinner } from './presentation/shared/LoadingSpinner'

interface AppContextData {
	authorized: boolean
	loading: boolean
	user: Profile | null
	singIn(): Promise<void>
	signOut(): Promise<void>
}

const AppContext = React.createContext<AppContextData>({} as AppContextData)
const AppProvider = ({ children }: PropsWithChildren) => {
	const profileUseCase = new ProfileUseCase(new ProfileRepository())
	const sessionUseCase = new SessionUseCase(new SessionRepository())

	const [loading, setLoading] = React.useState(true)
	const [user, setUser] = React.useState<Profile | null>(null)

	React.useEffect(() => {
		const dataFetch = async () => {
			await singIn()
		}
		dataFetch()
	}, [])

	async function singIn() {
		await VerifyAndRefreshTokenInterceptor()
		const accessToken = await sessionUseCase.getValidAccessTokenOrNull()
		if (accessToken == null) {
			setUser(null)
			setLoading(false)
			return
		}
		setUser(await profileUseCase.getProfile())
		setLoading(false)
	}

	async function signOut() {
		setUser(null)
		setLoading(false)
		await sessionUseCase.destroyToken()
	}

	if (loading) {
		return (
			<div className='fixed inset-0 flex items-center justify-center'>
				<LoadingSpinner />
			</div>
		)
	}

	return (
		<AppContext.Provider
			value={{
				authorized: !!user,
				user: user,
				loading,
				singIn,
				signOut,
			}}>
			{children}
		</AppContext.Provider>
	)
}

export { AppContext, AppProvider }
