import { jwtDecode, JwtPayload } from 'jwt-decode'

interface User {
	email: string
	uuid: string
}

interface UserPayload extends JwtPayload {
	user: User
}

export default class AccessToken {
	private accessToken: string

	public constructor(accessToken: string) {
		this.accessToken = accessToken
	}

	public getRaw(): string {
		return this.accessToken
	}

	public getEmail(): string | null {
		const claims = this.getPayloadMaybe()
		if (claims == null) {
			return null
		}
		let email = null
		try {
			email = claims.user.email
		} catch (error) {
			console.log(error)
		}
		return email
	}

	public getUid(): string | null {
		const claims = this.getPayloadMaybe()
		if (claims == null) {
			return null
		}
		let uuid = null
		try {
			uuid = claims.user.uuid
		} catch (error) {
			console.log(error)
		}
		return uuid
	}

	public getExpire(): number | null {
		const claims = this.getPayloadMaybe()
		if (claims == null) {
			return null
		}
		return claims.exp ?? null
	}

	public isValidToken(): boolean {
		const expire = this.getExpire()
		if (expire == null) {
			return false
		}

		if (Date.now() / 1000 >= expire) {
			return false
		}
		return true
	}

	private getPayloadMaybe(): UserPayload | null {
		let claims = null
		try {
			claims = jwtDecode<UserPayload>(this.accessToken)
		} catch (error) {
			console.error(error)
			return null
		}
		if (claims == null || claims == undefined) {
			return null
		}
		return claims
	}
}
