import React, { useState } from 'react'
import AuthRepository from 'domain/repository/auth/AuthRepository'
import VerificationEmailViewModel from 'presentation/viewmodel/verification-email/VerificationEmailViewModel'
import VerificationEmailCodeViewModel from 'presentation/viewmodel/verification-email-code/VerificationEmailCodeViewModel'
import VerificationRepository from 'domain/repository/verification/VerificationRepository'
import SessionRepository from 'domain/repository/session/SessionRepository'
import { Navigate, useNavigate } from 'react-router-dom'
import LoginUseCase from 'domain/usecase/login/LoginUseCase'
import SessionUseCase from 'domain/usecase/session/SessionService'
import VerificationEmailCodeComponent from '../view/verification-email-code/VerificationEmailCodeComponent'
import VerificationEmailComponent from '../view/verification-email/VerificationEmailComponent'

export default function VerificationScreen() {
	const authRepository = new AuthRepository()
	const sessionRepository = new SessionRepository()
	const verificationRepository = new VerificationRepository()
	const loginUseCase = new LoginUseCase(authRepository, sessionRepository, verificationRepository)

	const verificationEmailViewModel = new VerificationEmailViewModel(loginUseCase)
	const verificationEmailCodeViewModel = new VerificationEmailCodeViewModel(loginUseCase)

	const sessionUseCase = new SessionUseCase(sessionRepository)
	const accessToken = sessionUseCase.getValidAccessTokenOrNull()

	const navigate = useNavigate()
	const verification = loginUseCase.getActiveVerificationMaybe()
	const [isEmailCodeState, setEmailCodeState] = useState(verification != null && verification.isTransactionActive())

	if (accessToken != null) {
		return <Navigate to='/' replace />
	}
	return (
		<section>
			<div className='flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0'>
				<a href='#' className='flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white'>
					<img
						className='bg-white w-8 h-8 mr-2'
						style={{ padding: '1px', borderRadius: '8px' }}
						src='/image/icon_rounded.png'
						alt='logo'
					/>
					FoodTwoSteps
				</a>
				<div>
					{isEmailCodeState ? (
						<VerificationEmailCodeComponent
							viewModel={verificationEmailCodeViewModel}
							onVerificationEmailCompleted={() => navigate('/')}
						/>
					) : (
						<VerificationEmailComponent
							viewModel={verificationEmailViewModel}
							onVerificationEmail={() => setEmailCodeState(true)}
						/>
					)}
				</div>
			</div>
		</section>
	)
}
