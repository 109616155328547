import ProfileRepository from 'domain/repository/profile/ProfileRepository'
import { Profile } from 'grpc/gen/foodhunt/profile/v1/profile'

export default class ProfileUseCase {
	private repository: ProfileRepository

	constructor(repository: ProfileRepository) {
		this.repository = repository
	}

	public async getProfile(): Promise<Profile | null> {
		return await this.repository.getProfile()
	}

	public async updateProfile(profile: Profile) {
		return await this.repository.updateProfile(profile)
	}
}
