// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "foodhunt/profile/v1/profile.proto" (package "foodhunt.profile.v1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * @generated from protobuf message foodhunt.profile.v1.GetProfileRequest
 */
export interface GetProfileRequest {
}
/**
 * @generated from protobuf message foodhunt.profile.v1.Profile
 */
export interface Profile {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: bigint;
    /**
     * @generated from protobuf field: string first_name = 2;
     */
    firstName: string;
    /**
     * @generated from protobuf field: string second_name = 3;
     */
    secondName: string;
    /**
     * @generated from protobuf field: string nick_name = 4;
     */
    nickName: string;
    /**
     * @generated from protobuf field: int64 file_id = 5;
     */
    fileId: bigint;
    /**
     * @generated from protobuf field: float useful = 6;
     */
    useful: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created = 7;
     */
    created?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp updated = 8;
     */
    updated?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp deleted = 9;
     */
    deleted?: Timestamp;
    /**
     * @generated from protobuf field: string uid = 10;
     */
    uid: string;
    /**
     * @generated from protobuf field: string url = 11;
     */
    url: string;
    /**
     * @generated from protobuf field: int64 points = 12;
     */
    points: bigint;
}
/**
 * @generated from protobuf message foodhunt.profile.v1.GetProfileResponse
 */
export interface GetProfileResponse {
    /**
     * @generated from protobuf field: foodhunt.profile.v1.Profile profile = 1;
     */
    profile?: Profile;
}
/**
 * @generated from protobuf message foodhunt.profile.v1.UpdateProfileRequest
 */
export interface UpdateProfileRequest {
    /**
     * @generated from protobuf field: foodhunt.profile.v1.Profile profile = 1;
     */
    profile?: Profile;
}
/**
 * @generated from protobuf message foodhunt.profile.v1.UpdateProfileResponse
 */
export interface UpdateProfileResponse {
}
/**
 * @generated from protobuf message foodhunt.profile.v1.DeleteProfileRequest
 */
export interface DeleteProfileRequest {
}
/**
 * @generated from protobuf message foodhunt.profile.v1.DeleteProfileResponse
 */
export interface DeleteProfileResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class GetProfileRequest$Type extends MessageType<GetProfileRequest> {
    constructor() {
        super("foodhunt.profile.v1.GetProfileRequest", []);
    }
    create(value?: PartialMessage<GetProfileRequest>): GetProfileRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetProfileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfileRequest): GetProfileRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetProfileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.profile.v1.GetProfileRequest
 */
export const GetProfileRequest = new GetProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Profile$Type extends MessageType<Profile> {
    constructor() {
        super("foodhunt.profile.v1.Profile", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 2, name: "first_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "second_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "nick_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "file_id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 6, name: "useful", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "created", kind: "message", T: () => Timestamp },
            { no: 8, name: "updated", kind: "message", T: () => Timestamp },
            { no: 9, name: "deleted", kind: "message", T: () => Timestamp },
            { no: 10, name: "uid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "points", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<Profile>): Profile {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0n;
        message.firstName = "";
        message.secondName = "";
        message.nickName = "";
        message.fileId = 0n;
        message.useful = 0;
        message.uid = "";
        message.url = "";
        message.points = 0n;
        if (value !== undefined)
            reflectionMergePartial<Profile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Profile): Profile {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toBigInt();
                    break;
                case /* string first_name */ 2:
                    message.firstName = reader.string();
                    break;
                case /* string second_name */ 3:
                    message.secondName = reader.string();
                    break;
                case /* string nick_name */ 4:
                    message.nickName = reader.string();
                    break;
                case /* int64 file_id */ 5:
                    message.fileId = reader.int64().toBigInt();
                    break;
                case /* float useful */ 6:
                    message.useful = reader.float();
                    break;
                case /* google.protobuf.Timestamp created */ 7:
                    message.created = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.created);
                    break;
                case /* google.protobuf.Timestamp updated */ 8:
                    message.updated = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.updated);
                    break;
                case /* google.protobuf.Timestamp deleted */ 9:
                    message.deleted = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.deleted);
                    break;
                case /* string uid */ 10:
                    message.uid = reader.string();
                    break;
                case /* string url */ 11:
                    message.url = reader.string();
                    break;
                case /* int64 points */ 12:
                    message.points = reader.int64().toBigInt();
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Profile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0n)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string first_name = 2; */
        if (message.firstName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.firstName);
        /* string second_name = 3; */
        if (message.secondName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.secondName);
        /* string nick_name = 4; */
        if (message.nickName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.nickName);
        /* int64 file_id = 5; */
        if (message.fileId !== 0n)
            writer.tag(5, WireType.Varint).int64(message.fileId);
        /* float useful = 6; */
        if (message.useful !== 0)
            writer.tag(6, WireType.Bit32).float(message.useful);
        /* google.protobuf.Timestamp created = 7; */
        if (message.created)
            Timestamp.internalBinaryWrite(message.created, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp updated = 8; */
        if (message.updated)
            Timestamp.internalBinaryWrite(message.updated, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp deleted = 9; */
        if (message.deleted)
            Timestamp.internalBinaryWrite(message.deleted, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string uid = 10; */
        if (message.uid !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.uid);
        /* string url = 11; */
        if (message.url !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.url);
        /* int64 points = 12; */
        if (message.points !== 0n)
            writer.tag(12, WireType.Varint).int64(message.points);
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.profile.v1.Profile
 */
export const Profile = new Profile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfileResponse$Type extends MessageType<GetProfileResponse> {
    constructor() {
        super("foodhunt.profile.v1.GetProfileResponse", [
            { no: 1, name: "profile", kind: "message", T: () => Profile }
        ]);
    }
    create(value?: PartialMessage<GetProfileResponse>): GetProfileResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetProfileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfileResponse): GetProfileResponse {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.profile.v1.Profile profile */ 1:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.profile.v1.Profile profile = 1; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.profile.v1.GetProfileResponse
 */
export const GetProfileResponse = new GetProfileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateProfileRequest$Type extends MessageType<UpdateProfileRequest> {
    constructor() {
        super("foodhunt.profile.v1.UpdateProfileRequest", [
            { no: 1, name: "profile", kind: "message", T: () => Profile }
        ]);
    }
    create(value?: PartialMessage<UpdateProfileRequest>): UpdateProfileRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateProfileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateProfileRequest): UpdateProfileRequest {
        const message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            const [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* foodhunt.profile.v1.Profile profile */ 1:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    const u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    const d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateProfileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* foodhunt.profile.v1.Profile profile = 1; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.profile.v1.UpdateProfileRequest
 */
export const UpdateProfileRequest = new UpdateProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateProfileResponse$Type extends MessageType<UpdateProfileResponse> {
    constructor() {
        super("foodhunt.profile.v1.UpdateProfileResponse", []);
    }
    create(value?: PartialMessage<UpdateProfileResponse>): UpdateProfileResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateProfileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateProfileResponse): UpdateProfileResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateProfileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.profile.v1.UpdateProfileResponse
 */
export const UpdateProfileResponse = new UpdateProfileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteProfileRequest$Type extends MessageType<DeleteProfileRequest> {
    constructor() {
        super("foodhunt.profile.v1.DeleteProfileRequest", []);
    }
    create(value?: PartialMessage<DeleteProfileRequest>): DeleteProfileRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteProfileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteProfileRequest): DeleteProfileRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteProfileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.profile.v1.DeleteProfileRequest
 */
export const DeleteProfileRequest = new DeleteProfileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteProfileResponse$Type extends MessageType<DeleteProfileResponse> {
    constructor() {
        super("foodhunt.profile.v1.DeleteProfileResponse", []);
    }
    create(value?: PartialMessage<DeleteProfileResponse>): DeleteProfileResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteProfileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteProfileResponse): DeleteProfileResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteProfileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        const u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message foodhunt.profile.v1.DeleteProfileResponse
 */
export const DeleteProfileResponse = new DeleteProfileResponse$Type();
/**
 * @generated ServiceType for protobuf service foodhunt.profile.v1.ProfileAPI
 */
export const ProfileAPI = new ServiceType("foodhunt.profile.v1.ProfileAPI", [
    { name: "GetProfile", options: {}, I: GetProfileRequest, O: GetProfileResponse },
    { name: "UpdateProfile", options: {}, I: UpdateProfileRequest, O: UpdateProfileResponse },
    { name: "DeleteProfile", options: {}, I: DeleteProfileRequest, O: DeleteProfileResponse }
]);
