import SessionRepository from 'domain/repository/session/SessionRepository'
import AuthRepository from 'domain/repository/auth/AuthRepository'
import VerificationRepository from 'domain/repository/verification/VerificationRepository'
import LoginUseCase from 'domain/usecase/login/LoginUseCase'

export default async function VerifyAndRefreshTokenInterceptor() {
	const authRepository = new AuthRepository()
	const sessionRepository = new SessionRepository()
	const verificationRepository = new VerificationRepository()
	const loginUseCase = new LoginUseCase(authRepository, sessionRepository, verificationRepository)

	const accessToken = await sessionRepository.getAccessToken()
	if (accessToken && !accessToken.isValidToken()) {
		const refreshToken = await sessionRepository.getRefreshTokenRaw()
		if (refreshToken === null) {
			await sessionRepository.destroyToken()
			return
		}
		const tokenPair = await loginUseCase.refreshToken(refreshToken)
		if (tokenPair === null) {
			await sessionRepository.destroyToken()
		}
	}
}
