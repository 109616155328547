// @generated by protobuf-ts 2.9.4
// @generated from protobuf file "foodhunt/profile/v1/profile.proto" (package "foodhunt.profile.v1", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { ProfileAPI } from "./profile";
import type { DeleteProfileResponse } from "./profile";
import type { DeleteProfileRequest } from "./profile";
import type { UpdateProfileResponse } from "./profile";
import type { UpdateProfileRequest } from "./profile";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetProfileResponse } from "./profile";
import type { GetProfileRequest } from "./profile";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service foodhunt.profile.v1.ProfileAPI
 */
export interface IProfileAPIClient {
    /**
     * @generated from protobuf rpc: GetProfile(foodhunt.profile.v1.GetProfileRequest) returns (foodhunt.profile.v1.GetProfileResponse);
     */
    getProfile(input: GetProfileRequest, options?: RpcOptions): UnaryCall<GetProfileRequest, GetProfileResponse>;
    /**
     * @generated from protobuf rpc: UpdateProfile(foodhunt.profile.v1.UpdateProfileRequest) returns (foodhunt.profile.v1.UpdateProfileResponse);
     */
    updateProfile(input: UpdateProfileRequest, options?: RpcOptions): UnaryCall<UpdateProfileRequest, UpdateProfileResponse>;
    /**
     * @generated from protobuf rpc: DeleteProfile(foodhunt.profile.v1.DeleteProfileRequest) returns (foodhunt.profile.v1.DeleteProfileResponse);
     */
    deleteProfile(input: DeleteProfileRequest, options?: RpcOptions): UnaryCall<DeleteProfileRequest, DeleteProfileResponse>;
}
/**
 * @generated from protobuf service foodhunt.profile.v1.ProfileAPI
 */
export class ProfileAPIClient implements IProfileAPIClient, ServiceInfo {
    typeName = ProfileAPI.typeName;
    methods = ProfileAPI.methods;
    options = ProfileAPI.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: GetProfile(foodhunt.profile.v1.GetProfileRequest) returns (foodhunt.profile.v1.GetProfileResponse);
     */
    getProfile(input: GetProfileRequest, options?: RpcOptions): UnaryCall<GetProfileRequest, GetProfileResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetProfileRequest, GetProfileResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateProfile(foodhunt.profile.v1.UpdateProfileRequest) returns (foodhunt.profile.v1.UpdateProfileResponse);
     */
    updateProfile(input: UpdateProfileRequest, options?: RpcOptions): UnaryCall<UpdateProfileRequest, UpdateProfileResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateProfileRequest, UpdateProfileResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: DeleteProfile(foodhunt.profile.v1.DeleteProfileRequest) returns (foodhunt.profile.v1.DeleteProfileResponse);
     */
    deleteProfile(input: DeleteProfileRequest, options?: RpcOptions): UnaryCall<DeleteProfileRequest, DeleteProfileResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteProfileRequest, DeleteProfileResponse>("unary", this._transport, method, opt, input);
    }
}
